import { Box,Paper,Button,Typography } from "@mui/material"
import { styles } from "./cards_old"
import { Link } from "react-router-dom"
import ProductList from "./productList"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { updateProduct } from "../redux/products"
const Products=()=>{
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(updateProduct({}))
    },[])

return (
    <Box sx={{ padding: "32px", width: "100%",display:"flex",flexDirection:"column", gap:"32px" }} >

                <Paper sx={{ width: "100%", bgcolor: "#fff", padding: "32px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography sx={styles.header}>Products</Typography>
                    <Button style={{width:"250px"}} variant="contained" component={Link} to="/product/new" >+ Add Products</Button>
                </Paper>
                <ProductList/>

            </Box>
)
}

export default Products