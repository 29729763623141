import { Box, Button, Divider, InputBase, Typography, InputAdornment, IconButton, Checkbox, CircularProgress, Paper, Stepper, Step, StepLabel, Stack, styled, stepConnectorClasses, StepConnector } from "@mui/material";
import { updateEmail, updateMobile, updateFirstName, updateLastName, updatePassword, updateOtpCheck, updateMemberResponse, updateRole, updateId, updateProfile, updateUserData, updateUserName  } from "../redux/user_reducer";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import mayi_logo from "../assets/mayi_logo.png";
import signupImage from "../assets/Frame 91-1.jpg";
import CustomAxios from '../utils/CustomAxios';
import { useMediaQuery } from 'react-responsive';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import background from "../assets/Background.jpg"

export const inputStyles = {
    inputBase: { border: "1px solid", borderColor: "grey.400", maxWidth: { xs: "100%", sm: "100%" }, width: "100%", borderRadius: "5px", height: "48px", fontSize: "14px", padding: "14px", color: "grey.600" },
    label: { marginBottom: "8px", fontWeight: 400, fontSize: "14px", color: "grey.900", textAlign: "left" },
    terms: { fontWeight: 400, fontSize: "14px", whiteSpace: "pre-wrap" },
    paper: { padding: { sm: "32px", xs: "32px" }, display: "flex", alignItems: 'center', justifyContent: "center", flexDirection: 'column', bgcolor: "#fff", width: { sm: "50%", xs: "100%" } },
    button: { height: "3rem", textTransform: "none", fontWeight: 600, fontSize: "14px", maxWidth: "100%", "&:hover": { bgcolor: "primary.light", color: "primary.contrastText" }, borderRadius: "5px", fontSize: "", textTransform: 'none', "&:focus": { border: "0px solid white" } },
    heading: {},
    logo: { width: "100px", cursor: "pointer" },
    subHeading: { marginTop: "12px", fontWeight: "700", fontSize: "14px" },
    constent: { marginBottom: "16px", fontWeight: "400", fontSize: "14px", textAlign: "center" }
};

const SignupStepperForm = () => {
    const dispatch = useDispatch();
    const first_name = useSelector(state => state.user.first_name);
    const last_name = useSelector(state => state.user.last_name);
    const email = useSelector(state => state.user.email);
    const password = useSelector(state => state.user.password);
    const [showPassword, setShowPassword] = useState({password:false,con_password:false});
    const [referralCode, setReferralCode] = useState("");
    const [response, setResponse] = useState("");
    const [isloading, setIsloading] = useState(false);
    const navigate = useNavigate();
    const [agree, setAgree] = useState(false);
    const [errors, setErrors] = useState({});
    const queryParameters = new URLSearchParams(window.location.search);
    const clientId = "521447616048-relr5c80sac2rudgmfub8kantovfc5pe.apps.googleusercontent.com"; // Replace with your actual client ID
    const authtoken=localStorage.getItem("authtoken")
    const user_data = useSelector(state=>state.user)
    useEffect(()=>{
        if (authtoken){
            navigate("/")
        }
    
    },[])
    
    const handleSummit = () => {
        const formData = {
            email: email,
            first_name: first_name,
            last_name: last_name,
            password: password,
            referralCode: referralCode,
            username:user_data.user_name,
            mobile_no:user_data.mobile_no,
            con_password:user_data.con_password
        };

        const validate = validateForm(formData);
        if (validate && agree) {
            signup();
        }
    };

    useEffect(() => {
        setReferralCode(queryParameters.get('code')??"");
    }, []);

    const signup = async () => {
        setIsloading(true);
        await CustomAxios.post(`signup/`, {
            email: email,
            first_name: first_name,
            last_name: last_name,
            password: password,
            referralcode: referralCode,
            isactive:false,
            username:user_data.user_name,
            mobileno:user_data.mobile_no

        }).then(data => {
            setIsloading(false);
            localStorage.setItem("user", data.data);
            if (data.data.message === "Registration Successful!") {
                // console.log(data.data.user_id)
                dispatch(updatePassword(""))
                dispatch(updateUserName(""))
                dispatch(updateFirstName(""))
                dispatch(updateLastName(""))
                dispatch(updateEmail(data.data.email))
                dispatch(updateMobile(data.data.mobileno))
                dispatch(updateId(data.data.user_id))
                dispatch(updateOtpCheck('signup'));
                navigate("/otp-verification");
            } else {
                setResponse(data.data.message);
            }
        }).catch(error => {
            setIsloading(false);
            // console.log(error);
        });
    };

    const handleTogglePasswordVisibility = (val) => {
        if(val === "con"){
            setShowPassword((prevShowPassword) =>{
                return{
                    ...prevShowPassword,
                    con_password:!prevShowPassword.con_password
                }
            });
        }
        else{

            setShowPassword((prevShowPassword) => {
                return{
                    ...prevShowPassword,
                    password:!prevShowPassword.password
                }
            });
        }
    };

    const isMobile = useMediaQuery({ maxWidth: 600 });
    const isTablet= useMediaQuery({ maxWidth: 900,minWidth:600 });
    const isLaptop = useMediaQuery({ query: '(min-width: 900px)' });

    const validateForm = (formData) => {
        let valid = true;
        const newErrors = {};
        if (!formData.first_name.trim()) {
            newErrors.first_name = 'Enter your First name.​';
            valid = false;
        }
        if (!formData.last_name.trim()) {
            newErrors.last_name = 'Enter your Last name.​';
            valid = false;
        }

        const emailPattern = /^\S+@\S+\.\S+$/;
        if (!formData.email.trim()) {
            newErrors.email = 'Enter your email address.​';
            valid = false;
        } else if (!emailPattern.test(formData.email)) {
            newErrors.email = 'Please enter valid email address';
            valid = false;
        }
        if (!formData.password.trim()) {
            newErrors.password = 'Please enter your password.';
            valid = false;
        } else if (formData.password.length < 8 || !/[A-Z]/.test(formData.password) || !/[a-z]/.test(formData.password) || !/\d/.test(formData.password) || !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(formData.password)) {
            newErrors.password = "The password must be at least 8 characters long and contain uppercase and lowercase letters, special characters, and numbers.";
            valid = false;
        }
        if (!agree) {
            newErrors.checkbox = "Please agree to terms and Policy.​";
        }
        if(!formData.username.trim()){
            newErrors.username = 'Enter your profile/username name.'
            valid = false
        }
        else if(formData.username.length>12){
            newErrors.username = 'Maximum 12 characters allowed.'
            valid = false
        }
        else if(formData.username.length<3){
            newErrors.username = 'Minimum 3 characters allowed.'
            valid = false
        }
        if(!formData.mobile_no.trim()){
            newErrors.mobile_no = 'Enter your mobile number.'
            valid = false
        }
        else if(formData.mobile_no.length !== 10){
            newErrors.mobile_no = 'Enter valid number.'
            valid = false
        }
        if(!formData.referralCode.trim()){
            newErrors.referralCode = "Please Enter your Sponsor Referral code";
            valid = false;
        }
        if (!formData.con_password.trim()) {
            newErrors.con_password = 'Please re-enter your password.';
            valid = false;
        } else if (formData.password !== formData.con_password) {
            newErrors.con_password = "Confirm password not matched.";
            valid = false;
        }
        
        // console.log("-----errors-------",newErrors)
        setErrors(newErrors);
        return valid;
    };

    const validateFormOnNext = (step) => {
        let formData =  {
            email: email,
            first_name: first_name,
            last_name: last_name,
            password: password,
            referralCode: referralCode,
            username:user_data.user_name,
            mobile_no:user_data.mobile_no,
            con_password:user_data.con_password
        }
        let valid = true;
        const newErrors = {};
        if(step === 0){

            if (!formData.first_name.trim()) {
                newErrors.first_name = 'Enter your First name.​';
                valid = false;
            }
            if (!formData.last_name.trim()) {
                newErrors.last_name = 'Enter your Last name.​';
                valid = false;
            }
            if(!formData.username.trim()){
                newErrors.username = 'Enter your profile/user name.'
                valid = false
            }
            else if(formData.username.length<3){
                newErrors.username = 'Minimum 3 characters allowed.'
                valid = false
            }
            else if(formData.username.length>12){
                newErrors.username = 'Maximum 12 characters allowed.'
                valid = false
            }
        }
        else if(step === 1){

            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/;
            if (!formData.email.trim()) {
                newErrors.email = 'Enter your email address.​';
                valid = false;
            } else if (!emailPattern.test(formData.email)) {
                newErrors.email = 'Please enter valid email address';
                valid = false;
            }

            if(!formData.mobile_no.trim()){
                newErrors.mobile_no = 'Enter your mobile number.'
                valid = false
            }
            else if(formData.mobile_no.length !== 10){
                newErrors.mobile_no = 'Enter valid number.'
                valid = false
            }
        }
        else if(step === 2){

            if (!formData.password.trim()) {
                newErrors.password = 'Please enter your password.';
                valid = false;
            } else if (formData.password.length < 8 || !/[A-Z]/.test(formData.password) || !/[a-z]/.test(formData.password) || !/\d/.test(formData.password) || !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(formData.password)) {
                newErrors.password = "The password must be at least 8 characters long and contain uppercase and lowercase letters, special characters, and numbers.";
                valid = false;
            }

            if (!formData.con_password.trim()) {
                newErrors.con_password = 'Please re-enter your password.';
                valid = false;
            } else if (formData.password !== formData.con_password) {
                newErrors.con_password = "Confirm password not matched.";
                valid = false;
            }
        }
        else if(step === 3){
            if (!agree) {
                newErrors.checkbox = "Please agree to terms and Policy.​";
            }
        }
        
        
        
        
        // console.log("-----errors-------",newErrors)
        setErrors(newErrors);
        valid && setActiveStep(active_step + 1)
    };

    useEffect(() => {
        dispatch(updateEmail(""));
        dispatch(updatePassword(""));
    }, [dispatch]);

    const passwordVisible = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </InputAdornment>
        ),
    };

    const handleKeyPress = (e,step) => {
        if (e.key === 'Enter') {
            validateFormOnNext(step)
            // handleSummit();
        }
    };

    const homePage = () => {
        navigate("/");
    };

    // const handleGoogleSuccess = (credentialResponse) => {
    //     const details = jwtDecode(credentialResponse.credential);
    //     // console.log(details);
    //     const { given_name, family_name, email } = details;
    //     // Dispatch these details to update the Redux store or make an API call to register/login the user
    //     // console.log(given_name,family_name,email)
    //     dispatch(updateFirstName(given_name));
    //     dispatch(updateLastName(family_name));
    //     dispatch(updateEmail(email));

    //     // Redirect to the password page
    //     navigate("/");
        
    // };

    // const handleGoogleError = () => {
    //     // console.log('Login Failed');
    // };

    const handleGoogleSuccess = async (credentialResponse) => {
        const details = jwtDecode(credentialResponse.credential);
        // console.log(details);
        const { given_name, family_name, email } = details;
        // console.log(given_name, family_name, email);
        
        const payload = {
            email: email,
            first_name: given_name,
            last_name: "family_name" in details?family_name:"",
            referralcode: referralCode? referralCode:"",
            is_active: true,
            registration_type: "google"
        };
        // console.log(payload)
    
        // console.log(given_name);
        // console.log(family_name);
        // console.log(email);
    
        setIsloading(true);
        try {
            const response = await CustomAxios.post(`signup/`, payload);
            setIsloading(false);
    
            if (response.data.message === "Registration Successful!") {
                const decode = jwtDecode(response.data.access_token);
                localStorage.setItem("address", JSON.stringify(response.data.add));
                localStorage.setItem('authtoken', response.data.access_token);
                localStorage.setItem('refreshToken', response.data.refresh_token);
                // console.log(response.data.add);
                // console.log(decode);
                dispatch(updateMemberResponse(response.data));
                dispatch(updateFirstName(decode.first_name));
                dispatch(updateLastName(decode.last_name));
                dispatch(updateEmail(decode.email));
                dispatch(updateRole(decode.role));
                dispatch(updateId(decode.id));
                if (response.data.att.length > 0) {
                    dispatch(updateProfile(response.data.att[0].original));
                }
                dispatch(updateEmail(decode.email));
                navigate("/");
            } else {
                setErrors({ passworderror: response.data.message });
            }
        } catch (error) {
            setIsloading(false);
            console.error(error);
        }
    };
    

    let signup_steps=['basic info','contact','security','referral']
    const [active_step,setActiveStep] = useState(0)

    const StepperConnector_styles = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
          top: "12px",
          left: 'calc(-50% + 20px)',
          right: 'calc(50% + 20px)',
        },
        [`&.${stepConnectorClasses.active}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#53AEFF',
          },
        },
        [`&.${stepConnectorClasses.completed}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#53AEFF',
          },
        },
        [`& .${stepConnectorClasses.line}`]: {
          borderColor: "#D6DAE2",
          borderTopWidth: 2,
          borderRadius: 1,
        },
      }));
      
    

    return (
            <GoogleOAuthProvider clientId={clientId}>
                <Box component={Paper} elevation={isMobile ?0:1} sx={[isLaptop || isTablet ? { left: "50%", top: "50%", position: "absolute", transform: 'translate(-50%, -50%)'} : "",{display: "flex", alignItems: { xs: "center", sm: "initial" }, flexDirection: "column", width: { md: "750px", xs: "100%" }, maxWidth: {xs:"100%",sm:"440px"} },isMobile&&{borderRadius:"unset"}]} >
                    {/* <Box src={signupImage} sx={{ clipRule: "", width: { md: "40%", xs: "100%", sm: "40%" }, height: { xs: "50vh", sm: "100vh", md: "auto" }, maxheight: "80vh", overflowY: "scroll", backgroundSize: "auto", objectFit: "cover" }} component="img" /> */}
                    {isMobile && <Box src={background} sx={{ width: { md: "40%", xs: "100%", sm: "40%" }, height: { xs: "50vh", sm: "100vh", md: "auto" }, maxheight: "80vh", overflowY: "scroll", backgroundSize: "auto", objectFit: "fill" }} component="img" />}
                    <Stack sx={[{alignItems:"center",gap:{xs:"24px",md:"29px"},p:{xs:"16px",sm:"32px",},width:"100%"}]}>
                        <Stack sx={{alignItems:"center"}}>
                            <Box src={mayi_logo} component='img' sx={inputStyles.logo} onClick={homePage} />
                            <Typography sx={inputStyles.subHeading} color="grey.900">Join us!</Typography>
                            <Typography sx={inputStyles.constent} color="grey.900"> Create your account and start exploring.</Typography>
                        </Stack>
                        <Stepper sx={{width:"100%"}} activeStep={active_step} alternativeLabel 
                        connector = {<StepperConnector_styles/>}
                        >
                            {
                                signup_steps.map((label,l_id)=>(
                                    <Step key={l_id} >
                                        <StepLabel sx={{fontWeight:400,"& .MuiStepLabel-label":{mt:"4px !important"},"& .Mui-active":{fontWeight:"700 ! important"},"& .Mui-active MuiStepConnector-line":{borderColor:"red"},fontSize:"12px",lineHeight:"20px",letterSpacing:"0.25px",color:"#171C21",textTransform:"capitalize"}}>{label}</StepLabel>
                                    </Step>
                                ))
                            }
                        </Stepper>
                        {
                            active_step ===0 ?
                            <Stack sx={{gap:"16px",width:"100%"}}>
        
                                <Box sx={{ display: "flex", gap: "16px", flexDirection: { xs: "column", sm: "row" } }}>
                                        <Box>
                                            <Typography sx={inputStyles.label} color="grey.700">First Name <span style={{ color: "red" }}>*</span></Typography>
                                            <InputBase autoFocus autoComplete="given-name" value={first_name} sx={inputStyles.inputBase} placeholder="Enter your First Name" onKeyDown={(e)=>handleKeyPress(e,active_step)} onChange={(e) => dispatch(updateFirstName(e.target.value))} />
                                            {errors.first_name && <Typography sx={{ mt:"4px",fontWeight: 400, fontSize: "14px", color: "error.light"}}>{errors.first_name}</Typography>}
                                        </Box>
                                        <Box>
                                            <Typography sx={inputStyles.label} color="grey.700">Last Name <span style={{ color: "red" }}>*</span></Typography>
                                            <InputBase autoComplete="family-name" value={last_name} sx={inputStyles.inputBase} placeholder="Enter your Last Name" onKeyDown={(e)=>handleKeyPress(e,active_step)} onChange={(e) => dispatch(updateLastName(e.target.value))} />
                                            {errors.last_name && <Typography sx={{ mt:"4px",fontWeight: 400, fontSize: "14px", color: "error.light"}}>{errors.last_name}</Typography>}
                                        </Box>
                                </Box>
                                <Box>
                                    <Typography sx={inputStyles.label} color="grey.700">Profile / Username <span style={{ color: "red" }}>*</span></Typography>
                                    <InputBase autoComplete="member_username" value={user_data.user_name} sx={inputStyles.inputBase} placeholder="Set Profile / Username" onKeyDown={(e)=>handleKeyPress(e,active_step)} onChange={(e) => dispatch(updateUserData({"type":'user_name',"data":e.target.value.replace(" ","")}))} />
                                    {errors.username && <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "error.light",mt:"4px"}}>{errors.username}</Typography>}
                                </Box>
                            </Stack>
                            :
                            active_step === 1?
                            <Stack sx={{gap:"16px",width:"100%"}}>
                                <Box>
                                    <Typography sx={inputStyles.label} color="grey.700">Email ID <span style={{ color: "red" }}>*</span></Typography>
                                    <InputBase autoFocus autoComplete="email" value={email} sx={inputStyles.inputBase} placeholder="Enter your Email ID" onKeyDown={(e)=>handleKeyPress(e,active_step)} onChange={(e) => dispatch(updateEmail(e.target.value))} />
                                    {errors.email && <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", mt: "4px" }}>{errors.email}</Typography>}
                                </Box>
                                <Box>
                                    <Typography sx={inputStyles.label}>Mobile Number <Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
                                    <InputBase autoComplete="tel-national" value={user_data.mobile_no} sx={inputStyles.inputBase} startAdornment={<Typography sx={{pr:"5px",fontSize:"14px"}}>+91</Typography>}  onKeyDown={(e)=>handleKeyPress(e,active_step)} placeholder="(Enter your 10 Digit mobile number)" 
                                    onChange={(e)=>dispatch(updateUserData({"type":"mobile_no","data":e.target.value.replace(/\D/g, '')}))}
                                    />
                                    {errors?.mobile_no && <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", mt: "4px" }}>{errors.mobile_no}</Typography>}
                                </Box>
                            </Stack>
                            :
                            active_step === 2?
                                <Stack sx={{gap:"16px",width:"100%"}}>
                                    <Box>
                                        <Typography sx={inputStyles.label} color="grey.700">Password <span style={{ color: "red" }}>*</span></Typography>
                                        <InputBase autoFocus value={password} type={showPassword.password ? 'text' : 'password'} placeholder="Enter your password" sx={inputStyles.inputBase} onKeyDown={(e)=>handleKeyPress(e,active_step)} onChange={(e) => dispatch(updatePassword(e.target.value))} endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton onClick={()=>handleTogglePasswordVisibility("password")} edge="end">
                                                    {showPassword.password ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        } />
                                        {errors.password && <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", mt: "4px" }}>{errors.password}</Typography>}
                                    </Box>
                                    <Box>
                                        <Typography sx={inputStyles.label} color="grey.700">Confirm Password <span style={{ color: "red" }}>*</span></Typography>
                                        <InputBase value={user_data.con_password} type={showPassword.con_password ? 'text' : 'password'} placeholder="Re-enter password" sx={inputStyles.inputBase} onKeyDown={(e)=>handleKeyPress(e,active_step)} onChange={(e) => dispatch(updateUserData({"type":"con_password","data":e.target.value}))} endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton onClick={()=>handleTogglePasswordVisibility("con")} edge="end">
                                                    {showPassword.con_password ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        } />
                                        {errors.con_password && <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", mt: "4px" }}>{errors.con_password}</Typography>}
                                    </Box>
                                </Stack>
                            :
                            active_step === 3?
                                <Stack sx={{gap:"16px",width:"100%"}}>
                                    <Box>
                                        <Typography sx={inputStyles.label} color="grey.700">Sponsor Referral Code</Typography>
                                        <input autoFocus autoComplete="referral_code" value={referralCode} placeholder="Enter Referral Code" style={{...inputStyles.inputBase,outline:"none",borderColor:"#bdbdbd",fontFamily:"Roboto,Helvetica, Arial, sans-serif"}} onChange={(e) => setReferralCode(e.target.value)} />
                                        <Typography sx={{fontWeight: 400, fontSize: "14px",marginTop:"10px"}}>Don't have a referral code? <span style={{color:"rgba(39, 161, 255, 1)",cursor:"pointer",fontWeight: 400, fontSize: "14px",}} onClick={()=>setReferralCode("MIQGQ9M4")}>Click to copy</span></Typography>
                                        {/* <InputBase autoFocus autoComplete="referral_code" sx={inputStyles.inputBase}  placeholder="Enter Referral Code" onKeyDown={()=>(e)=>handleKeyPress(e,active_step)} onChange={(e) => setReferralCode(e.target.value)} /> */}
                                        {errors.referralCode && <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", mt: "4px" }}>{errors.referralCode}</Typography>}
                                    </Box>
                                    <Box>
                                        <Box sx={{ display: "flex", alignItems: "center", }}>
                                            <Checkbox
                                                checked={agree}
                                                onChange={() => setAgree(!agree)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                sx={{pl:0}}
                                            />
                                            <Typography sx={inputStyles.terms} ><a href="https://mayiiq.com/terms-of-services/" style={{ textDecoration: "none", color: "rgba(39, 161, 255, 1)", fontWeight: "400", textDecorationLine: "underline" }} >Terms&nbsp;of&nbsp;Services</a >&nbsp;and&nbsp;<a style={{ textDecoration: "none", color: "rgba(39, 161, 255, 1)", fontWeight: "400", textDecorationLine: "underline" }} href="https://mayiiq.com/privacy-policy/">Privacy Policy</a></Typography>
                                        </Box>
                                        {errors.checkbox && <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", mt: "4px" }}>{errors.checkbox}</Typography>}
                                        <Typography sx={{ color: "error.light",mt:"4px" }}>{response}</Typography>
                                    </Box>
                                </Stack>
                            :null
                        }
                        <Stack sx={{gap:"20px",width:"100%"}}>
                            {
                                active_step >=1?
                                <Stack direction={{xs:"column",sm:"row"}} sx={{justifyContent:"space-between",gap:"20px"}}>
                                    <Button variant="outlined" sx={{...inputStyles.button,borderRadius:"8px",width:{xs:"100%",sm:"178px"},height:"40px","&:hover":{bgcolor:"unset"},textTransform:"capitalize"}} onClick={()=>{setActiveStep(active_step-1)}}>Previous</Button>
                                    {
                                       active_step >=3?
                                       <Button variant="contained" sx={{...inputStyles.button, width:{xs:"100%",sm:"178px"},height:"40px",borderRadius:"8px",}} onClick={handleSummit} >{isloading ? <CircularProgress color="inherit" size={30} /> : "Sign up"}</Button>
                                       : 
                                        <Button variant="contained" sx={{...inputStyles.button, width:{xs:"100%",sm:"178px"},height:"40px",borderRadius:"8px",}}  onClick={()=>{validateFormOnNext(active_step)}}>Next</Button>
                                    }
                                </Stack>
                                :
                                <Button variant="contained" sx={{...inputStyles.button,height:"40px",borderRadius:"8px",}} fullWidth onClick={()=>{validateFormOnNext(active_step)}}>Next</Button>
                            }
                            {/* <Divider sx={{ fontSize: "14px", fontFamily:"Roboto" }} >Or</Divider> */}
                            {/* <Box sx={{ display: 'flex', flexDirection:"column", justifyContent: "center"}}> */}
                                <Box sx={{ display: "flex", flexDirection:"column", justifyContent: "center",alignItems:"center",gap:"20px" }}>
                                    {/* <GoogleLogin
                                        onSuccess={handleGoogleSuccess}
                                        // onError={handleGoogleError}
                                    /> */}
                                    <Stack direction="row">
                                        <Typography sx={{ textAlign: "center", fontSize: "14px" }}> Already have an account?&nbsp;</Typography><Link to="/login" style={{ textDecoration: "none", textDecorationLine: "underline", fontSize: "14px" }}><Typography sx={{ textDecoration: "none", fontSize: "14px" }} color="primary">Sign in</Typography></Link>
                                    </Stack>
                                </Box>
                            {/* </Box> */}
                        </Stack>
                    </Stack>
                    
                    {/* <Box sx={{ padding: { sm: "32px "32px" }, display: "flex", alignItems: 'center', justifyContent: "center", flexDirection: 'column', bgcolor: "#fff", width: { sm: "60%", xs: "100%" } }}>
                        <Box sx={{ display: "flex", flexDirection: 'column', gap: "16px", width: "100%" }}> */}
                            {/* <Box sx={{ display: "flex", gap: "16px", flexDirection: { xs: "column", sm: "row" } }}>
                                <Box>
                                    <Typography sx={inputStyles.label} color="grey.700">First Name <span style={{ color: "red" }}>*</span></Typography>
                                    <InputBase sx={inputStyles.inputBase} placeholder="Enter your First Name" onKeyDown={handleKeyPress} onChange={(e) => dispatch(updateFirstName(e.target.value))} />
                                    {errors.first_name && <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", marginBottom: "-20px" }}>{errors.first_name}</Typography>}
                                </Box>
                                <Box>
                                    <Typography sx={inputStyles.label} color="grey.700">Last Name <span style={{ color: "red" }}>*</span></Typography>
                                    <InputBase sx={inputStyles.inputBase} placeholder="Enter your Last Name" onKeyDown={handleKeyPress} onChange={(e) => dispatch(updateLastName(e.target.value))} />
                                    {errors.last_name && <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", marginBottom: "-20px" }}>{errors.last_name}</Typography>}
                                </Box>
                            </Box> */}
                            {/* <Box>
                                <Typography sx={inputStyles.label} color="grey.700">Email <span style={{ color: "red" }}>*</span></Typography>
                                <InputBase sx={inputStyles.inputBase} placeholder="Enter email address" onKeyDown={handleKeyPress} onChange={(e) => dispatch(updateEmail(e.target.value))} />
                                {errors.email && <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", marginBottom: "-20px" }}>{errors.email}</Typography>}
                            </Box>
                            <Box>
                                <Typography sx={inputStyles.label} color="grey.700">Password <span style={{ color: "red" }}>*</span></Typography>
                                <InputBase type={showPassword ? 'text' : 'password'} placeholder="Enter password" sx={inputStyles.inputBase} onKeyDown={handleKeyPress} onChange={(e) => dispatch(updatePassword(e.target.value))} endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                } />
                                {errors.password && <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", marginBottom: "-20px" }}>{errors.password}</Typography>}
                            </Box>
                            <Box>
                                <Typography sx={inputStyles.label} color="grey.700">Sponsor Referral Code</Typography>
                                <InputBase sx={inputStyles.inputBase} value={referralCode} placeholder="Enter Referral Code" onKeyDown={handleKeyPress} onChange={(e) => setReferralCode(e.target.value)} />
                                {errors.referralCode && <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", marginBottom: "-20px" }}>{errors.referralCode}</Typography>}
                            </Box>
                            <Box>
                                <Box sx={{ display: "flex", alignItems: "center", }}>
                                    <Checkbox
                                        checked={agree}
                                        onChange={() => setAgree(!agree)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <Typography sx={inputStyles.terms} ><a href="https://mayiiq.com/terms-of-services/" style={{ textDecoration: "none", color: "rgba(39, 161, 255, 1)", fontWeight: "400", textDecorationLine: "underline" }} >Terms&nbsp;of&nbsp;Services</a >&nbsp;and&nbsp;<a style={{ textDecoration: "none", color: "rgba(39, 161, 255, 1)", fontWeight: "400", textDecorationLine: "underline" }} href="https://mayiiq.com/privacy-policy/">Privacy Policy</a></Typography>
                                </Box>
                                {errors.checkbox && <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", marginBottom: "-20px" }}>{errors.checkbox}</Typography>}
                            </Box>
                            <Typography sx={{ color: "error.light" }}>{response}</Typography>
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <Button variant="contained" sx={inputStyles.button} onClick={handleSummit} >{isloading ? <CircularProgress color="inherit" size={30} /> : "Sign up"}</Button>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: "center" }}>
                                <Typography sx={{ textAlign: "center", fontSize: "14px" }}> Already have an account?&nbsp;</Typography><Link to="/login" style={{ textDecoration: "none", textDecorationLine: "underline", fontSize: "14px" }}><Typography sx={{ textDecoration: "none", fontSize: "14px" }} color="primary">Sign in</Typography></Link>
                            </Box>
                            <Divider sx={{ marginTop: "24px", marginBottom: "20px", fontSize: "14px" }} >Or</Divider>
                            <Box sx={{ display: "flex", justifyContent: "center", marginTop: "16px" }}>
                                <GoogleLogin
                                    onSuccess={handleGoogleSuccess}
                                    // onError={handleGoogleError}
                                />
                            </Box> */}
                            {/* <Divider sx={{ marginTop: "24px", marginBottom: "20px", fontSize: "14px" }} >Or</Divider> */}
                        {/* </Box>
                    </Box> */}
                </Box>
            </GoogleOAuthProvider>
        );

};
export default SignupStepperForm;