import { Box, Button, IconButton, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import CustomAxios from '../utils/CustomAxios';
import CircularProgress from '@mui/material/CircularProgress';
import { useMediaQuery } from 'react-responsive';
import { updateSnackBarMessage, updateSnackBarOpen, updateSnackBarSeverity } from '../redux/snackbar';
import dayjs from 'dayjs';
import { Redirection } from './Redirections';

export  function OtpTransPin({otpModal, setOtpmodal ,reTransPinOtp,setReTransPinOtp ,handleSubmitPin  ,sendOTP ,time, setTime,otpNotmatch ,setOtpNotMatch ,retransClose,handleKycSubmit ,kycPresent,kyc_details ,fetchuserdetails,scrollToSection}){
      const [otp,setOtp] = useState('') 
      const [isloading, setIsloading] = useState(false)
      const [resend,setResend]=useState()
      const [maskedEmail, setMaskedEmail] = useState('');
      const [maskedMobile, setMaskedMobile] = useState('');
      const isMobile = useMediaQuery({ maxWidth: 600 });
      const email = useSelector(state => state.user.email )
      const mobileno = useSelector(state => state.user.mobile_no )
      const id = useSelector(state => state.user.id )
      const verify = useSelector(state => state.user.otp )
      const handleClose = () => {
          setOtpNotMatch(false)
          setOtpmodal({ value: false, type: '' });
          setReTransPinOtp(false)
      };
      const dispatch = useDispatch()
      const handleChange = (newValue) => {
        setOtp(newValue)
    }
    const handleComplete = async(value) => {  //---------------> Verifying the transaction otp
        setIsloading(true)
        let formattedDob = dayjs(kyc_details.dob, 'DD-MM-YYYY').format('YYYY-MM-DD');
        let dobString = formattedDob.toString();
        await CustomAxios.post(`otp-verification/`,
        {
            id:id,     
            otp:otp,
            verifyOtpFor:otpModal.type,
            kyc_details:{...kyc_details,dob:dobString}??null,
            kyc_id:kyc_details?.id??null

        }
    ).then(({data})=>{
        if(data.message=="Congratulations, your account have been successfully verified" && verify=="transOtp" && otpModal.value){
            console.log(data)
            handleSubmitPin()
            setIsloading(false)
        } 
        if(data.message=="Congratulations, your account have been successfully verified" && verify=="kycOtp" && otpModal.value){
          console.log(data)
          fetchuserdetails()
          dispatch(updateSnackBarMessage("Updated Successfully."))
          dispatch(updateSnackBarSeverity("success"))
          dispatch(updateSnackBarOpen(true))
          setOtpmodal({ value: false, type: '' });
          setIsloading(false)
      } 
        // if(data.message=="Congratulations, your account have been successfully verified" && verify=="transOtp" && reTransPinOtp){    //---------------> This is for 2 otps while resetting the Transaction PIN
        //     console.log(data)
        //     handleClearPin()
        //     setTranspinPresent(false)
        //     setReTransPinOtp(false)
        //     setIsloading(false)
        // }    
        if(data.message=="OTP is not matching" ){
            setIsloading(false)
            setOtpNotMatch(true)
        }
}).catch(error=>{
    setIsloading(false)
    console.log(error)
})
  }

      const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleComplete(); 
        }
      };
      
      useEffect(() =>{
        setOtp('')
        
      },[otpModal,reTransPinOtp])
      useEffect(() => {
        if (time > 0) {
          const timerId = setInterval(() => {
            setTime(prevTime => prevTime - 1);
          }, 1000);
          return () => clearInterval(timerId); // Cleanup interval on component unmount
        }
        else{
            setResend(true)
        }
      }, [time]);
      // const formatTime = (seconds) => {
      //   const minutes = Math.floor(seconds / 60);
      //   const remainingSeconds = seconds % 60;
      //   return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
      // };
      // console.log(otpNotmatch)

      const handleResendOtp =(e) => {
        setOtp('')
        otpModal.value&&sendOTP(e,otpModal.type)
      }

      const maskEmail = (email) => {       //--------------> This is for masking email
        const [localPart, domain] = email.split('@');
        if (localPart.length <= 2) {
            return `${localPart}@${domain}`; 
        }
        const visiblePart = localPart.slice(-2); 
        const maskedPart = 'X'.repeat(localPart.length - 2);
        return `${maskedPart}${visiblePart}@${domain}`;
    };
      const maskMobileNo = (mobileNumber) => {     //-----------> This is for masking mobile
        if (mobileNumber.length <= 2) {
            return mobileNumber; 
        }
        
        const visiblePart = mobileNumber.slice(-2); 
        const maskedPart = 'X'.repeat(mobileNumber.length - 2); 
        return `${maskedPart}${visiblePart}`;
    };

    useEffect(() => {
        if (email) {
            setMaskedEmail(maskEmail(email));
        }
        if (mobileno) {
          setMaskedMobile(maskMobileNo(mobileno));
      }
    }, [email,mobileno]);
    return(
        <Box>
            <Dialog
        open={otpModal.value || reTransPinOtp}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
       { (mobileno=="" || mobileno==null) ?
       <Box>
       <Redirection
          headings="Update Mobile Number"
          navigation="mobileno"
          content="To receive your OTP securely, please update your mobile number in your profile.Click the button below to update now."
          button="Update Mobile Number"
          handleClose = {handleClose}
          scrollToSection={scrollToSection}
          />
   </Box> 
       :<Box sx={{maxWidth:isMobile?"100%":"383px",width:"100%",padding:isMobile?"16px":"24px",gap:"16px",minHeight:"320px",height:"100%",display:"flex",flexDirection:"column"}}>
            <Box sx={{display:"flex",flexDirection:"column",gap:"4px"}}>
                <Box sx={{display:"flex",justifyContent:"space-between"}}>
                    <Typography  sx={{ fontWeight: 600, fontSize: "16px",lineHeight:"24px"}} color="primary">Verify Your E-mail Address</Typography>
                    <CloseIcon onClick={()=>{setOtpNotMatch(false);setOtpmodal({ value: false, type: '' });setReTransPinOtp(false)}} sx={{cursor:"pointer"}}/>
                </Box>
                {/* <Typography sx={{ fontWeight: 400, fontSize: "14px",lineHeight:"20px"}} color="grey.900">A verification code has been sent to your registered mobile number {maskedMobile&&maskedMobile}</Typography> */}
                <Typography sx={{ fontWeight: 400, fontSize: "14px",lineHeight:"20px"}} color="grey.900">A verification code has been sent to your registered E-mail Address {maskedEmail && maskedEmail }</Typography>
            </Box>
            <Box sx={{display:"flex",flexDirection:"column",gap:"12px"}}>
                {otpModal.value && <Typography sx={{ fontWeight: 400, fontSize: "14px",lineHeight:"20px"}} color="grey.900">Please enter the code below to {(otpModal.type=="transactionpin" && retransClose)||( otpModal.type=="kyc"&&kycPresent) ?'modify':otpModal.type=="kyc"?'add':'set'} {otpModal.type=="kyc"?"your KYC":"secured Transaction PIN"}</Typography>}
                {reTransPinOtp && <Typography sx={{ fontWeight: 400, fontSize: "14px",lineHeight:"20px"}} color="grey.900">Please enter the code below to modify secured Transaction PIN</Typography>}
                <MuiOtpInput gap={isMobile?.1:1} sx={{padding:"5px"}} value={otp} length={6} onKeyDown={(e)=>handleKeyPress(e)} onChange={handleChange}/>
               {otpNotmatch  ?<Typography sx={{ fontWeight: 400, fontSize: "12px",lineHeight:"16px"}} color="error">Invalid code. Please enter the valid code.</Typography>:<Typography sx={{ fontWeight: 400, fontSize: "12px",lineHeight:"16px"}} color="error">Resend Code ({Math.floor(time / 60)}:{('0' + (time % 60)).slice(-2)} remaining)</Typography>}
               
            </Box>
            <Box sx={{ display:"flex", justifyContent:"space-between"}}>
                        <Button disabled={otp.length<6?true:false} variant="contained" sx={{bgcolor:"primary.light",color:"primary.contrastText",height:"48px",maxWidth:"77px",width:"100%",textTransform: "none"}} onClick={handleComplete}><Typography sx={{fontWeight: 400, fontSize: "16px",lineHeight:"24px"}}>{isloading? <CircularProgress color="inherit" size={30}/> : "Submit"}</Typography></Button>
                        <Button disabled={!resend || time!==0 ?true:false} sx={{bgcolor:"transparent",border:"none",outline:"none"}} onClick={(e)=>handleResendOtp(e)}><Typography sx={{fontWeight: 400, fontSize: "16px",lineHeight:"24px",textTransform:"none"}} color={resend&& time==0 ?"primary":"#BFC7D4"}>Resend OTP</Typography></Button>
            </Box>
        </Box>}
      </Dialog>
        </Box>
    )
}