
import { Box, Button, Paper, Typography } from "@mui/material"

import { useDispatch, useSelector } from "react-redux"
import { updateActiveSideBAr } from "../redux/active_bar"
import AdminSidebar from "../components/adminSideBar"
import Header from "../components/header"
import Products from "../components/product"

import ProductForm from "../components/addProducts"
import ProductUpdateForm from "../components/updateProduct"
import Header1 from "../components/header1"


const Product = () => {
    const dispatch = useDispatch()
    // console.log("its is product page")
    const urlId = window.location.pathname.split('/')[2]
    const product = useSelector(state => state.product?.product)
    // console.log(product)

    return <>
       
        <Box sx={{ bgcolor: { sm: "grey.100", xs: "#fff" }, minHeight: "calc(100vh - 120px)" }}>
                <Header1 />
                <Box sx={{ display: "flex", marginTop: "80px" }}>
                    <AdminSidebar />
                    {
                        isNaN(urlId) === false?
                        <ProductUpdateForm/>
                        :
                        <ProductForm/>
                    }

                </Box>
            
           
            

        </Box>

    </>

}
export default Product