import { Modal } from "@mui/material"
import OtpVerifyOption from "./otp_verify_option"
import { useSelector } from "react-redux"
import { updateOtpVerifyPopup } from "../redux/user_reducer"
import { useDispatch } from "react-redux"


const OTP_popup=()=>{

    const dispatch = useDispatch()
    const open=useSelector(state=>state.user.OtpVerifyPopup)
    const handleClose = () => {
        dispatch(updateOtpVerifyPopup(false))
        // setErrors({})
        // setIsVerified(true)
        // setOpen(false);
    }

return <Modal open={open}
// onClose={handleClose}
aria-labelledby="child-modal-title"
aria-describedby="child-modal-description"
>
    <OtpVerifyOption/>
</Modal>
}

export default OTP_popup