

import { Box, Button, Divider, InputBase, InputLabel, Paper, Typography } from "@mui/material"
import { alpha, styled } from '@mui/material/styles'
import { updateEmail, updateMobile, updateUserName, updateOtpCheck, updateId } from "../redux/user_reducer"
import mayi_logo from "../assets/mayi_logo.png"
import {inputStyles} from "../forms/signup_form"
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useMediaQuery } from "react-responsive"
import background from "../assets/Background.jpg"
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useState } from "react"
import { useNavigate } from "react-router-dom";
import axios from "axios"
import CustomAxios from '../utils/CustomAxios';
import forgotPasswordImage from "../assets/Frame 91-2.jpg"
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect } from "react"

const ForgotPassword=()=>{

    const dispatch = useDispatch()
const visibility=()=>{
    // // console.log("password visible")
}
const email = useSelector(state => state.user.email )
const [errors,setErrors]=useState({})
const [isloading, setIsloading] = useState(false)
const navigate = useNavigate()
const username=useSelector(state=>state.user.user_name)

const handleSummit=async ()=>{
    setIsloading(true)
    const formData={
       
        username:username,
            verify:"forgot_password"
       
    }
    const error = {
        username:""
    }
    const validate=validateForm(formData)
    // console.log(validate)
    if(validate){
        const response = await CustomAxios.post(`forgot_password/`, formData)
        setIsloading(false)
    // console.log(response.data)
    if (response.data.message=="success"){
        dispatch(updateOtpCheck('forgot_password'))
        dispatch(updateId(response.data.user.id))
        dispatch(updateEmail(response.data.user.email))
        dispatch(updateMobile(response.data.user.mobileno))
        dispatch(updateUserName(""))
        navigate("/otp-verification")
    }
    else{
        error.username = response.data.message
        setErrors(error)
    } 
    }   
    else{
        setIsloading(false)
    }
    // console.log(validate)
    
}
const validateForm = (formData) => {
    let valid = true;
    const newErrors = {};

    

    // Email validation
    const emailPattern = /^\S+@\S+\.\S+$/;
    if (!formData.username.trim()) {
      newErrors.username = 'Please enter User Name';
      valid = false;
    }
    // } else if (!emailPattern.test(formData.email)) {
    //   newErrors.email = 'Please enter valid email address';
    //   valid = false;
    // }

   
// console.log(valid)
    // Set errors state
    setErrors(newErrors);

    return valid;
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSummit(); 
    }
  };
  const homePage=()=>{
    navigate("/")
}

useEffect(()=>{
    dispatch(updateUserName(''))
},[])

const isMobile = useMediaQuery({ maxWidth: 600 });
    return(
        <Box sx={{width:"100%",bgcolor:"black",display: "flex", alignItems: 'center', justifyContent: "center", flexDirection: 'column'}}>
   {!isMobile&& <Box  sx={{height:"100vh",width:"100%"}} component='img' src={background} />}
   <Box component={!isMobile&&Paper} sx={{ left: "50%", top: "50%", position: {sm:"absolute"}, transform: {sm:'translate(-50%, -50%)'},display:"flex",justifyContent:"space-between",alignItems:{xs:"center",sm:"i;nitial"},flexDirection: {sm:"row", xs:"column"},width: { md: "750px", xs: "100%" }, maxWidth:{xs:"100%" ,md:"750px"},height:{xs:"auto",sm:"100vh",md:"auto"}}} >
        <Box src={forgotPasswordImage} sx={{width: {md:"50%", xs:"100%",sm:"50%"},height:{xs:"50vh",sm:"100vh",md:"auto"},maxheight:"80vh", overflowY:"scroll",backgroundSize:"auto",objectFit:"cover"}} component="img" />
        <Box sx={inputStyles.paper}>
            <Box src={mayi_logo} component='img' sx={inputStyles.logo} onClick={homePage}/>
                <Typography sx={inputStyles.subHeading} color="grey.900"> Forgot your password? </Typography>
                <Typography sx={inputStyles.constent} color="grey.900"> Don't worry, we'll help you reset it. </Typography>
            <Box sx={{ display: "flex", flexDirection: 'column', gap: "16px",width:"100%"}}>
                
                <Box>
                    <Typography sx={inputStyles.label} color="grey.700">Username <span style={{color:"red"}}>*</span></Typography>
                    <InputBase sx={inputStyles.inputBase} value={username} placeholder="Enter your Username" onChange={(e) => dispatch(updateUserName(e.target.value))} onKeyDown={(e)=>handleKeyPress(e)}/>
                    {errors.username && <Typography sx={{color:"error.light"}}>{errors.username}</Typography>}
                </Box>
                
                <Button variant="contained" sx={inputStyles.button} onClick={handleSummit}>{isloading? <CircularProgress color="inherit" size={30}/> : "Verify"}</Button>
                
            </Box>
           
        </Box>
        </Box>
        </Box>
        
    )
}
export default(ForgotPassword)