import { Box, capitalize, Paper, Typography } from "@mui/material"
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";


import { useEffect, useRef } from "react";

import { useState } from "react";
import { Bar, Line, Doughnut,Chart } from 'react-chartjs-2';




const GoalStatus = () => {
    const chart = useRef()
    const accumulate = useSelector(state => state.user.accumulated)
    const { account } = useSelector(state => state.user.member_response)
    const {pk_info}=useSelector(state => state.user.member_response)
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const isLaptop = useMediaQuery({ query: '(min-width: 900px)' });
    const [background,setBackground]=useState([])
    const [total,setTotal]=useState()
    const [completed,setCompleted]=useState()
    const [pending,setPending]=useState()
    const [shift,setShift]=useState(true) 
    const [dataSet,setDataset] = useState({ labels:['first', 'second', 'third', 'fourth', 'fifth',"additional"],
            datasets: [
              {
                label:"Earnings",
                data: [20, 20, 20, 20, 20], // Example sales data for each month
                backgroundColor:['#eeeeee','#e0e0e0','#bdbdbd','#9e9e9e','#757575'] , // Background color for bars
                borderColor:['#eeeeee','#e0e0e0','#bdbdbd','#9e9e9e','#757575'], // Border color for bars
                borderWidth: 0, // Border width for bars
                cutout: '90%',
                circumference: 270,
                rotation: 225,
                borderRadius:15,
                hoverBackgroundColor: background,  // Disables the hover background color change
                hoverBorderColor: background, 
            }
        ]})
        const color=['#FACC15', "#F97316", '#22C55E', '#22D3EE', '#818CF8']
    const grey=['#eeeeee','#e0e0e0','#bdbdbd','#9e9e9e','#757575']
    const labels= ['first', 'second', 'third', 'fourth', 'fifth',"additional"]

useEffect(()=>{
    
    const data=[20, 20, 20, 20, 20]
    console.log("accumulated data",pk_info)
    if (account&&pk_info){
        const milestone=pk_info.filter(data=>data.id==account.milestone_id)
        console.log(milestone)
        let background=[]
        let completed=[]
        let label=[]
        // account.milestone_id
        for (let i=0;i<color.length;i++){
            if(i<account.milestone_id-1){
                background.push(color[i])
                completed.push(data[i])
                label.push(labels[i])
                // console.log("success")
            }
            else if(i<account.milestone_id){
                // background.push(color[i])
                // console.log("?????????????????????????accoutn details",i<account.milestone_id)
                const total=((account.milestone_completed)*20)/milestone[0].total_cost
                // console.log("?????????????????????????total",total)

                const reducer=((milestone[0].total_cost-account.milestone_completed)*20)/milestone[0].total_cost
                // console.log("??????????????????reducer",reducer)
                // const non_complete=completed.reduce((a,b)=>a+b,0)
                // console.log(reducer,total)
                // const completed_value=(reducer-non_complete)-total
                if (account.milestone_id!=5){
                    background.push(color[i])
                completed.push(total)
                label.push(labels[i])

                background.push(grey[i])
                completed.push(reducer)
                label.push(labels[5])

                }
                else{
                    background.push(color[i])
                    completed.push(20)
                    label.push(labels[i])
                    
                }
                // console.log("success")
            }
            else{
                background.push(grey[i])
                completed.push(20)
                label.push(labels[i])
            }
        }
        // console.log(completed)
        // const  doubledDataValues= completed.flatMap(value => [value, value]);
        // console.log(doubledDataValues)
        // console.log("data set for analysis",label)
        const chartData = {

        labels:label,
            datasets: [
                {
                    label:"Earnings",
                    data: completed, // Example sales data for each month
                    backgroundColor:background , // Background color for bars
                    borderColor:background, // Border color for bars
                    borderWidth: 0, // Border width for bars
                    cutout: '90%',
                    circumference: 270,
                    rotation: 225,
                    borderRadius:15,
                    hoverBackgroundColor: background,  // Disables the hover background color change
                    hoverBorderColor: background, 
                }
            ]
        };
        setDataset(chartData)
    }
    

},[account,pk_info])
const addCurrencyAndCommafy = (num) => {
    // // console.log(num)

    let str = parseFloat(num).toFixed(2).toString().split('.');
    if (str[0].length >= 4) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    return "₹ " + str.join('.');

}
    


useEffect(()=>{
    if(pk_info){
        // console.log(pk_info)
        let total=0
        pk_info.map((item)=> total+=item.total_cost )
        let completed=0
        // console.log(total)
        setTotal(total)

        for(let i=0;i<account.milestone_id-1;i++){
            completed+= pk_info[i].total_cost
        }
        completed+=account.milestone_completed
        setCompleted(completed)
        setPending(total-completed)
    }

},[pk_info])

    
    const overlappingSegments = {
        id: "overlappingSegments",
        afterDatasetsDraw(chart, args, plugins) {
            // console.log("hello to overlapping ").
            const { ctx, data } = chart;
            // console.log(chart.getDatasetMeta(0).data[0])
            const x = chart.getDatasetMeta(0).data[0].x
            const y = chart.getDatasetMeta(0).data[0].y
            const angle = Math.PI / 180
            const length=data?.labels.length-1
            // console.log("?????????????????????/label lenght",data.labels,data.labels.length)
            chart.getDatasetMeta(0).data.forEach((value,index)=>{
                if (index<length && chart.getDataVisibility(index)){

                
                const innerRadius = chart.getDatasetMeta(0).data[index].innerRadius
            const outerRadius = chart.getDatasetMeta(0).data[index].outerRadius
            const endAngle = chart.getDatasetMeta(0).data[index].endAngle
            // const startAngle=chart.getDatasetMeta(0).data[0].startAngle
            const radius = (outerRadius - innerRadius) / 2
            
            // const xcoor = (innerRadius + radius) * Math.cos(endAngle + Math.PI)
            // const ycoor = (innerRadius + radius) * Math.sin(endAngle)
            // const radius
            const coordinates=[]
            for (let i=-0.03;i<=0.03;i+=0.01){
                const xCoor=(innerRadius + radius) * Math.cos(endAngle + Math.PI+i)
                const yCoor = (innerRadius + radius) * Math.sin(endAngle+i)
                coordinates.push({x:xCoor,y:yCoor})
            }
            // console.log(coordinates)
           
            ctx.save()
            ctx.fillStyle=data.datasets[0].backgroundColor[index]
            ctx.translate(x, y)
            ctx.beginPath()
            coordinates.forEach(({x,y})=>{
                ctx.arc(-x, y, radius+0.5,0, angle * 360, false)

            })
            // ctx.arc(-xcoor, ycoor, radius-2,0, angle * 360, false)
            ctx.fill()
            ctx.restore()
        }

            })

        }
    }
    // console.log(accumulate, account)
    const chartOptions = {
        maintainAspectRatio: true,
        // AspectRatio:"2",

        scales: {
            x: {
                display: false, // Hide the x-axis labels
            },
            y: {
                display: false, // Hide the y-axis labels
            },
        },
        plugins: {
            
            legend: {
               
                    display:false
            },
            tooltip: {
                // enabled: true,
                callbacks: {
                    label: function (tooltipItem) {
                      let label = tooltipItem.label || '';
                    //   console.log("hello",tooltipItem)
                    //   if (label) {
                    //     label += ': ';
                    //   }
                    //   label += tooltipItem.raw;
                    console.log("????????????????/tooltip lenght",tooltipItem.dataIndex)
                      label = 'Earnings'; // Custom value suffix
                      label+=":";
                    //   let shift=true
                    console.log(shift)
                    let index=0
                    if(shift){
                        index=tooltipItem.dataIndex
                    }
                    else{
                        index=tooltipItem.dataIndex+1
                    }
                    

                      let currentState=0
                      
                        if (index==0){
                            label+=accumulate?.bronze
                            console.log("first",tooltipItem.dataIndex)
                            if (tooltipItem.dataIndex==account?.milestone_id-1){
                                // console.log("hello to all one")
                                console.log("inside the value")
                                // shift=false
                                setShift(false)
                                currentState=account?.milestone_id
                            }
                        }
                        else if(index==1){
                            label+=accumulate?.silver
                            console.log("first",tooltipItem.dataIndex)
                            if (tooltipItem.dataIndex==account?.milestone_id-1){
                                // console.log("hello to all one")
                                console.log("inside the value")
                                setShift(false)
                               currentState=account?.milestone_id
                            }
                        }

                            
                        else if(index==3) {
                            label+=accumulate?.gold
                            console.log("first",tooltipItem.dataIndex)
                            if (tooltipItem.dataIndex==account?.milestone_id-1){
                                // console.log("hello to all one")
                                console.log("inside the value")
                                // shift=false
                                setShift(false)
                              currentState=account?.milestone_id
                            }
                        }
                            
                    
                          else if(index==4){
                            label+=accumulate?.platinum
                        console.log("first",tooltipItem.dataIndex)
                        if (tooltipItem.dataIndex==account?.milestone_id-1){
                            // console.log("hello to all one")
                            console.log("inside the value")
                            // shift=false
                            setShift(false)
                           currentState=account?.milestone_id
                        }
                          }
                        
                        else if(index==5){
                            label+=accumulate.diamond
                            console.log("first",tooltipItem.dataIndex)
                            if (tooltipItem.dataIndex==account?.milestone_id-1){
                                // console.log("hello to all one")
                                console.log("inside the value")
                                // shift=false
                                setShift(false)
                                currentState=account?.milestone_id
                            }
                        }
                        if (tooltipItem.dataIndex==account?.milestone_id-1){
                            if(tooltipItem.dataIndex==0){
                                label+=accumulate?.bronze
                            }
                            else if(tooltipItem.dataIndex==1){
                                label+=accumulate?.silver

                            }
                            else if(tooltipItem.dataIndex==2){
                                label+=accumulate?.gold

                            }
                            else if(tooltipItem.dataIndex==3){
                                label+=accumulate?.platinum

                            }
                            else if(tooltipItem.dataIndex==4){
                                label+=accumulate?.diamond

                            }


                        }
                        
                       
                        
                       
                    //   console.log("current State and shift",currentState,shift)
                      label+=" "+'Earned'
                      console.log(label)
                      return label;
                    },
                    title: function () {
                        // console.log("hello toall")
                      return 'Milestone Earnings'; // Custom title
                    },
                 } // Disable tooltips
            },
        },
    };
    const plugins = [overlappingSegments]



const stageNotification=(value)=>{
    // console.log(value)
    let stage=""
    switch (value) {
       
        case 1 :
            stage= "First"
            break;
        case 2 :
            stage= "Second"
            break;
        case 3:
            stage= "Third"
            break;
        case 4 :
            stage= "Fourth"
        break;
        case 5 :
            stage= "Fifth"
        break;
        
      
                      

    }
    return stage 
}
const customLegend = dataSet?.labels.map((label, index) => {
    // console.log(pk_info)
    if(account?.milestone_id>index){
        return(<>{pk_info&& <>
            <Box key={index} sx={{display:"flex",alignItems:"center",gap:"5px"}}>
              <Box style={{ backgroundColor: color[index],height:"10px",width:"10px" }}></Box>
              <Typography sx={{fontSize:"14px",fontWeight:"500px"}}>{capitalize( label)} Milestone </Typography><br/>
             
            </Box>
            <Box sx={{paddingLeft:"15px"}}>
                <Typography sx={{fontSize:"12px"}}>Purchase cost:₹ 1 - {pk_info[index]?.total_cost==-1 ? "Infinity":pk_info[index]?.total_cost} </Typography>
                <Typography sx={{fontSize:"12px"}}>Status:{index<account?.milestone_id-1?"Completed":index==account.milestone_id-1?"Inprogress":"Not Yet Started"}</Typography>
                <Typography sx={{fontSize:"12px"}}>Earnings:₹ {index==0&&accumulate?.bronze}{index==1&&accumulate?.silver} {index==2&&accumulate?.gold}{index==3&&accumulate?.platinum} {index==4&&accumulate?.diamond}</Typography>
        
            </Box></>}</>)

    }
    if(account?.milestone_id<index){
        return(<>{pk_info&& <>
            <Box key={index} sx={{display:"flex",alignItems:"center",gap:"5px"}}>
              <Box style={{ backgroundColor: color[index-1],height:"10px",width:"10px" }}></Box>
              <Typography sx={{fontSize:"14px",fontWeight:"500px"}}>{capitalize( label)} Milestone </Typography><br/>
             
            </Box>
            <Box sx={{paddingLeft:"15px"}}>
                <Typography sx={{fontSize:"12px"}}>Purchase cost:₹ 1 - {pk_info[index-1]?.total_cost==-1 ? "Infinity":pk_info[index-1]?.total_cost} </Typography>
                <Typography sx={{fontSize:"12px"}}>Status: {index<account.milestone_id-1?"Completed":index==account.milestone_id-1?"Inprogress":"Not Yet Started"}</Typography>
                <Typography sx={{fontSize:"12px"}}>Earnings:₹ {index-1==0&&accumulate.bronze}{index-1==1&&accumulate.silver} {index-1==2&&accumulate.gold}{index-1==3&&accumulate.platinum} {index-1==4&&accumulate.diamond} </Typography>
        
            </Box></>}</>)

    }
   
});
    

    return <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", padding: "24px", width: "100%", }} component={isLaptop ? Paper : Box}>


        <Typography sx={{ fontWeight: 700, fontSize: "16px" }} color="grey.900">Milestone Status</Typography>
        
            <Box sx={{display:"flex",alignItems:"center",justifyContent:"center",gap:"30px"}}>  
        <Box sx={{display:"flex",alignItems:"center",justifyContent:"center",width:{xs:"100%",md:"50%"}}}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", position: "relative", width: "500px", height: "500px" }}>
                {dataSet&&
                <Doughnut style={{ display: "flex", alignItems: "center", justifyContent: "center", position: "absolute", width: "377px", height: "377px" }} data={dataSet} options={chartOptions} plugins={plugins} />}
                <Box sx={{left:{xs:80,sm:100},bottom:{xs:80,sm:20},position:"absolute",display:"flex",alignItems:"center",flexDirection:"column",justifyContent:"center"}}>
                <Typography sx={{fontSize:{xs:"12px",sm:"12px"},fontWeight:"500",lineHeight:"16px",color:"#747775"}}>Start</Typography>
                <Typography sx={{fontSize:{xs:"16px",sm:"20px"},fontWeight:"500",lineHeight:"16px"}}>₹1</Typography>
                </Box>
                <Box sx={{right:{xs:70,sm:100},bottom:{xs:80,sm:20},position:"absolute",display:"flex",alignItems:"center",flexDirection:"column",justifyContent:"center"}}>
                <Typography sx={{fontSize:{xs:"12px",sm:"12px"},fontWeight:"500",lineHeight:"16px",color:"#747775"}}>Goal</Typography>
                <Typography sx={{fontSize:{xs:"16px",sm:"20px"},fontWeight:"500",lineHeight:"16px"}}>₹1,11,999</Typography>
                </Box>
                
                <Box sx={{}}>
                    <Typography sx={{fontSize:{xs:"16",sm:"22px"},fontWeight:"500",lineHeight:{xs:"24px",sm:"46px"},textAlign:"center"}}>Total Earnings</Typography>
                    <Typography sx={{fontSize:{xs:"28px",sm:"40px"},fontWeight:"600",lineHeight:{xs:"32px",sm:"46px"},textAlign:"center"}}>{accumulate?.total_accumulation}</Typography>
                    {/* <Typography sx={{fontSize:"12px",fontWeight:"500",lineHeight:"16px",textAlign:"center",width:{xs:"180px",sm:"229px"},marginTop:"12px",color:"#747775"}}>You're at <span style={{fontWeight:"600"}}>{addCurrencyAndCommafy(completed)} ({(Math.round((completed/total)*100))}%)</span> of your <span style={{fontWeight:"600"}}>₹1,11,999</span> goal. Nurture your health, add <span style={{fontWeight:"600"}}>{addCurrencyAndCommafy(pending)} ({(Math.round((pending/total)*100))}%)</span> more to your wealth.</Typography> */}
                    
                    <Typography sx={{fontSize:"14px",fontWeight:"600",lineHeight:"16px",textAlign:"center",width:{xs:"180px",sm:"229px"},marginTop:"12px",color:"#747775"}}>Available Withdrawal: {accumulate?.withdrawal_available}</Typography>
                    <Typography sx={{fontSize:"12px",fontWeight:"500",lineHeight:"16px",textAlign:"center",width:{xs:"180px",sm:"229px"},marginTop:"12px",color:"#747775"}}>You're at <span style={{fontWeight:"600"}}>{addCurrencyAndCommafy(account?.milestone_completed)} </span> of your <span style={{fontWeight:"600"}}>{stageNotification(account?.milestone_id)}</span> goal.</Typography>
                </Box>

            </Box>
            

        </Box>
        {pk_info && isLaptop&&
        <Box className="custom-legend" >
        {customLegend}
      </Box>}
      </Box>
        
    </Box>
}
export default GoalStatus