import { createSlice } from "@reduxjs/toolkit";


let initialState = {
    AllOrders : []
    
};


let OrdersSlice = createSlice({
    name: "orders",
    initialState: initialState,
    reducers: {
        updateAllOrders:(state, action,payload) => {
            console.log(payload,action)
            const order_id = action.payload.order_id;
            const order_status = action.payload.order_status;
            if(order_id){
               state.AllOrders = state.AllOrders.map((order) =>
        order.razorpay_order_id === order_id ? { ...order, status: order_status } : order
      ); 
            }
            else{
                state.AllOrders = action.payload
            }
      
        }
    }
    })

    export let { 
        updateAllOrders
    } = OrdersSlice.actions
    
    
    
    export default OrdersSlice.reducer;