
import { Box, Button, Paper, Typography } from "@mui/material"

import { useDispatch } from "react-redux"
import { updateActiveSideBAr } from "../redux/active_bar"
import AdminSidebar from "../components/adminSideBar"
import Header from "../components/header"
import Products from "../components/product"
import MessageBar from "./messageBar"
import Header1 from "../components/header1"


const Product = () => {
    const dispatch = useDispatch()
    // console.log("its is product page")

    return <>
       
        <Box sx={{ bgcolor: { sm: "grey.100", xs: "#fff" }, minHeight: "calc(100vh - 120px)" }}>
                <MessageBar/>
                <Header1 />
                <Box sx={{ display: "flex", marginTop: "120px" }}>
                    <AdminSidebar />
                    <Products/>

                </Box>
            
           
            

        </Box>

    </>

}
export default Product