import { useState } from 'react'
import { MuiOtpInput } from 'mui-one-time-password-input'

import { Box, Button, Divider, FormControlLabel, InputBase, InputLabel, Paper, Radio, RadioGroup, Typography } from "@mui/material"
import { alpha, styled } from '@mui/material/styles'
import { updateEmail, updateMobile, updateFirstName, updateLastName, updatePassword,updateRole,updateId, updateOtpCheck } from "../redux/user_reducer"
import mayi_logo from "../assets/mayi_logo.png"
import { useMediaQuery } from 'react-responsive';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {inputStyles} from "../forms/signup_form"
import { useDispatch, useSelector } from "react-redux";
import { Link,useNavigate, useParams } from "react-router-dom";
import {jwtDecode} from "jwt-decode"
import CustomAxios from '../utils/CustomAxios';
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios"
import otpImage from "../assets/otp.jpg"
import background from "../assets/Background.jpg"
import MessageBar from './messageBar'
import { useEffect } from 'react'
import OtpVerifyOption from '../components/otp_verify_option'
const OtpVerify = () => {
    const dispatch=useDispatch()
    const [response,setResponse]=useState('')
    const [otp, setOtp] = useState('')
    const [isloading, setIsloading] = useState(false)
    const [isreloading, setIsReloading] = useState(false)
    const [otpHasSend, setOtpHasSend] = useState(false)
    const [maskedEmail, setMaskedEmail] = useState('');
    const [maskedMobile, setMaskedMobile] = useState('');
    const [verificationType, setVerificationType] = useState('');
    const email = useSelector(state => state.user.email )
    const mobileno = useSelector(state => state.user.mobile_no )
    const id=useSelector(state=>state.user.id)
    const verify = useSelector(state => state.user.otp )
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}otp_check/`
    const [resend,setResend]=useState(false)
    // console.log(verify)
    //const url = "http://127.0.0.1:8000/mayi/otp_check/"

    const param = useParams()
        // console.log(verify)
    const handleChange = (newValue) => {
        setOtp(newValue)
    }
    const navigate = useNavigate()
    const handleComplete = async(value) => {
        setIsloading(true)
        setResponse('')
        const user=localStorage.getItem("user")
        // console.log(email)
        // console.log(user,email,value)
        await CustomAxios.post(`otp-verification/`,
        {
            id:id,
            email:email,
           
            otp:otp
        }
    ).then(({data})=>{
        setIsloading(false)
        console.log(data,verify)
        if(data.message=='Congratulations, your account have been successfully verified' && (verify=="signup" || verify == "login")){
            const decode=jwtDecode(data.access_token)
            // // console.log(decode)
            localStorage.setItem('authtoken', data.access_token);
            localStorage.setItem('refreshToken', data.refresh_token)
            dispatch(updateFirstName(decode.first_name))
            dispatch(updateLastName(decode.last_name))
            dispatch(updateEmail(decode.email))
            dispatch(updateRole(decode.role))
            dispatch(updateId(decode.id))
            navigate("/")
        }
        else{
            if (data.message=='Congratulations, your account have been successfully verified' && verify=="forgot_password"){
            const decode=jwtDecode(data.access_token)
            // // console.log(decode)
            localStorage.setItem('authtoken', data.access_token);
            localStorage.setItem('refreshToken', data.refresh_token)
            dispatch(updateEmail(decode.email))
            navigate("/password-change/forgot")
        }
        else{
            if (data.message=='Congratulations, your account have been successfully verified' && verify=="profile"){
                const decode=jwtDecode(data.access_token)
                // console.log(data)
            // // console.log(decode)
            localStorage.setItem('authtoken', data.access_token);
            localStorage.setItem('refreshToken', data.refresh_token)
            dispatch(updateEmail(decode.email))
            navigate("/profile")
            }
            else{
            setResponse(data.message)}
        }
    }
        
        // // console.log(data)
        
    }).catch(error=>{
        setIsloading(false)
        // console.log(error)
    })
      }
      const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleComplete(); 
        }
      };
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const homePage=()=>{
        navigate("/")
    }
    const handleSummit=async ()=>{
        setIsReloading(true)
        setIsloading(true)
        const formData={
           id:id,
            email:email,
            verify:verify,
            verificationType:verificationType
           
        }
        const error = {
            email:""
        }
        //const validate=validateForm(formData)
        
        const response = await CustomAxios.post(`forgot_password/`, formData)
        setIsReloading(false)
        console.log(response.data)
        if (response.data.message=="otp has been send"){
            dispatch(updateOtpCheck(verify))
            navigate("/otp-verification")
            setResend(false)
            setTime(150)
            setIsloading(false)
        }
        else{
            error.email = response.data.message
            //setErrors(error)
        } 
        //// console.log(validate)
        
    }
    const [time, setTime] = useState(150); // 150 seconds equals 2 minutes and 30 seconds

    useEffect(() => {
      if (time > 0) {
        const timerId = setInterval(() => {
          setTime(prevTime => prevTime - 1);
        }, 1000);
        return () => clearInterval(timerId); // Cleanup interval on component unmount
      }
      else{
setResend(true)
      }
    }, [time]);
useEffect(()=>{
otpHasSend&&setTime(150)
},[otpHasSend])
    const handleRadioChange = (event) => {
        setVerificationType(event.target.value);
      };

      const handleGenarateOTP = async() => {
        setIsloading(true)
        setResponse('')
       try{
        const response = await CustomAxios.post(`otp_generation/`,
                {
                    id:id,
                    verificationType:verificationType,
                    email:email,
                    verify:verify
                })
                if(response.data.message = "otp has been send"){
                    setIsloading(false)
                    setTime(150)
                    setOtpHasSend(true)
                }
       }
       catch (e) {
        console.log(e)
       }
    }
  console.log(verificationType,otpHasSend)
    const formatTime = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };
    const maskEmail = (email) => {
        const [localPart, domain] = email.split('@');
        if (localPart.length <= 2) {
            return `${localPart}@${domain}`; // If local part is too short to mask
        }
        const visiblePart = localPart.slice(-2); // Last 2 characters
        const maskedPart = 'X'.repeat(localPart.length - 2); // Mask all but the last 2 characters
        return `${maskedPart}${visiblePart}@${domain}`;
    };
    const maskMobileNo = (mobileNumber) => {
        if (mobileNumber.length <= 2) {
            return mobileNumber; // If mobile number is too short to mask
        }
        
        const visiblePart = mobileNumber.slice(-2); // Last 2 digits
        const maskedPart = 'X'.repeat(mobileNumber.length - 2); // Mask all but the last 2 digits
        return `${maskedPart}${visiblePart}`;
    };
console.log(mobileno)
    useEffect(() => {
        if (email) {
            setMaskedEmail(maskEmail(email));
        }
        if (mobileno) {
            setMaskedMobile(maskMobileNo(mobileno));
        }
    }, [email,mobileno]);
    return (
        <Box sx={{width:"100%",bgcolor:"black",display: "flex", alignItems: 'center', justifyContent: "center", flexDirection: 'column'}}>
            
   {!isMobile&& <Box  sx={{height:"100vh",width:"100%"}} component='img' src={background} />}
        <Box component={!isMobile&&Paper} sx={{ left: "50%", top: "50%", position: "absolute", transform: 'translate(-50%, -50%)',display:"flex",alignItems:{xs:"center",sm:"initial"},justifyContent:"center",flexDirection: {sm:"row", xs:"column"},width: { md: "750px", xs: "100%" }, maxWidth:{xs:"100%" ,sm:"750px"}}} >
        <Box src={otpImage} sx={{width: {sm:"50%", xs:"100%"},backgroundSize:"auto",objectFit:"cover"}} component="img" />
        <OtpVerifyOption/>
        </Box>
        </Box>
    )
}
export default OtpVerify