import { Box, MenuItem, Select, Skeleton, Tab, Tabs, Typography,Button } from "@mui/material";
import { styles } from "../modules/my_wallet";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Calendar } from "primereact/calendar";
import { DatePicker } from "@mui/x-date-pickers";
import { Dropdown } from 'primereact/dropdown';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { MultiSelect } from 'primereact/multiselect';
import 'primereact/resources/themes/saga-blue/theme.css';  // Import theme (optional)
import 'primereact/resources/primereact.min.css';           // Import PrimeReact CSS
import 'primeicons/primeicons.css';  
import { updateOpenWithdrawRequest,updateWithdrawAmount,updateWithdrawAccount, updateWithdrawAmountChange,updateWithdrawId,updateWithdrawRequest, updateWithdrawDisabled, updateMemberResponse, updateWithdraw, updateTransactionPinVerification, updateOverallSpend, updateAccumulated ,updateCurrentAmount} from "../redux/user_reducer";
import { useDispatch } from "react-redux";
import CustomAxios from "../utils/CustomAxios";
import CircularProgress from '@mui/material/CircularProgress';
export function TransactionsAndWithdrawals() {

    const [value, setValue] = useState('Transaction History');
    const [transactions, setTransactions] = useState([]);
    const id=useSelector(state=>state.user.id)
    const dispatch = useDispatch()
    const [isLoading,setIsLoading]=useState(false)
    const [dates, setDates] = useState(null);
    const [selectedTransactionType, setSelectedTransactionType] = useState("");
    const [selectedWallet, setSelectedWallet] = useState("");
    const [filteredTransactions, setFilteredTransactions] = useState();
    let userResponse = useSelector(state => state.user.member_response)
    let  withdrawRequest=useSelector(state=>state.user.withdraRequest)
    let [filteredWithdraw,setFilteredWithdraw]=useState([])
    const [withdrawDates,setWithdrawDates]=useState([])
    const [withdrawStatus,setWithdrawStatus]=useState("")
    let user=useSelector(state => state.user.id)
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    useEffect(() => {
        const formattedData = userResponse?.wallet_history?.filter(item => item.status === 'completed' || item.status === 'paid')
        .map(item => ({
            ...item,
            date: formatDate(item.created_on),
        }));
    formattedData?.reverse();
    setTransactions(formattedData);
    }, [userResponse])

    const columns = [
        { id: 'date', label: 'Date', minWidth: 110 },
        { id: 'transaction_id', label: 'Transaction ID', minWidth: 170 },
        {
            id: 'transaction_sub_type',
            label: 'Transaction Type',
            minWidth: 170,
        },
        {
            id: 'tag',
            label: 'Wallet',
            minWidth: 170,
        },
        {
            id: 'amount',
            label: 'Amount',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toFixed(2),
        },
    ];

    const formatAmount = (amount, transactionType) => {
        const sign = transactionType === 'c'  ? '+' : '-';
        const formattedAmount = new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(amount);
        return `${sign} ${formattedAmount}`;
    };

    const capitalizeFirstLetter = (str) => {
        if (str == 'top-up'){
            str = 'Deposited'
        }
        if (str == 'purchase'){
            str = 'Mayi pay purchase'
        }
        return str?.charAt(0).toUpperCase() + str?.slice(1);
    };
    const wallet = (str) => {
        if (str == 'mc'){
            str = 'Mayi Pay'
        }
        if (str == 'me'){
            str = 'Mayi Earnings'
        }
        return str;
    };
    const transaction_types = [
        { name: 'Transfer'},
        { name: 'Mayi pay purchase'},
        { name: 'Deposited'},
        { name: 'Withdraw'} ,
    ];
    const withdraw_statuses = [
        { name: 'Completed'},
        { name: 'Cancelled'},
        { name: 'Requested'},
        { name: 'Inprogress'},
 
    ];
    const withdrawStatusMapping =
        { "completed": 'Completed',
        'cancelled': 'Cancelled',
        'requested': 'Requested',
        'inprogress': 'Inprogress'}
 
    

    const wallet_types = [
        { name: 'Mayi Pay'},
        { name: 'Mayi Earnings'},
    ];
      const typeMapping = {
        "mc": "Mayi Pay",
        "me": "Mayi Earnings",
        "top-up":"Deposited"
    };
    const transactionTypeMapping = {
        "top-up":"Deposited",
        "transfer":"Transfer",
        "purchase":"Mayi pay purchase",
    };
    let filtered
    useEffect(()=>{
             filtered = selectedTransactionType.length
      ? transactions.filter((transaction) => 
      selectedTransactionType.some((type) => type.name === transactionTypeMapping[transaction.transaction_sub_type])
    )
      : transactions;

        setFilteredTransactions(filtered)
    },[selectedTransactionType,transactions])
    useEffect(()=>{
        console.log(withdrawStatus)
        filtered = withdrawStatus.length
 ? withdrawRequest.filter((transaction) => 
 withdrawStatus.some((type) =>type.name === withdrawStatusMapping[transaction.status])
)
 : withdrawRequest;

   setFilteredWithdraw(filtered)
},[withdrawStatus,withdrawRequest])
    useEffect(()=>{
             filtered = selectedWallet.length
      ? transactions.filter((transaction) => 
      selectedWallet.some((wallet) => wallet.name === typeMapping[transaction.tag])
    )
      : transactions;

        setFilteredTransactions(filtered)
    },[selectedWallet,transactions])
    useEffect(()=>{
        filtered = dates ? transactions.filter(transaction => {
            console.log(transaction.created_on)
        const transactionDate = new Date(transaction.created_on).setHours(0, 0, 0, 0);
        console.log(transactionDate)
        const startDate = new Date(dates[0]).setHours(0, 0, 0, 0);
        const endDate = new Date(dates[1]).setHours(23, 59, 59, 999);
        return transactionDate >= startDate && transactionDate <= endDate;
    }) : transactions;
    setFilteredTransactions(filtered)
   },[dates,transactions])

   useEffect(()=>{
    console.log("??????????????????",withdrawDates,)
    filtered = withdrawDates.length ? withdrawRequest?.filter(withdrawRequest => {
        console.log(withdrawRequest.created_on,withdrawDates)
    const transactionDate = new Date(withdrawRequest.created_on).setHours(0, 0, 0, 0);
    // console.log(transactionDate)
    const startDate = new Date(withdrawDates[0]).setHours(0, 0, 0, 0);
    const endDate = new Date(withdrawDates[1]).setHours(23, 59, 59, 999);
    return transactionDate >= startDate && transactionDate <= endDate;
}) : withdrawRequest;
setFilteredWithdraw(filtered)

   },[withdrawDates,withdrawRequest])

   useEffect(()=>{
    const totalPurchaseAmount = transactions?.filter((transaction) => (transaction.tag === 'mc' && transaction.transaction_type === 'd'  ) ).reduce((sum, transaction) => sum + transaction.amount, 0.00);
    dispatch(updateOverallSpend(totalPurchaseAmount)
)
   },[transactions])
    // console.log(dates)
    // console.log(filters)
    const statusUpdate=async(id,value)=>{
        setIsLoading(true);
    //   if(isLoading)  {
            await CustomAxios.put(`update_withdraw/${id}`,{
                status:value
    
            }).then(res=>{
                if(res.data.response=="successfully updated"){
                    // withdraRequest()
    
                    console.log("?????????????????update the request")
                    if(value=='cancelled'){
                        dispatch(updateWithdrawDisabled(false))
                        dispatch(updateTransactionPinVerification("not verified"))
                        dispatch(updateWithdraw(false))
                        dispatch(updateWithdrawAmount(""))
                        dispatch(updateWithdrawAccount(""))
                        dispatch(updateWithdrawAmountChange(false))
                        // setIsLoading(false)
                    }
                    const payload = {
                        user_id: user
                    }
                    CustomAxios.post(`get_details/`, payload).then(response => {
                        console.log(response)
                        dispatch(updateMemberResponse(response.data))   
                        dispatch(updateWithdrawRequest(response.data.withdrawRequest))
                        dispatch(updateTransactionPinVerification('not verified'))
                        dispatch(updateWithdraw(false))
                        dispatch(updateWithdrawAmountChange(false))
                        dispatch(updateAccumulated(response.data.accumulated))
                        setIsLoading(false)
                    }
                    ).catch(error => {
                        // console.log(error)
                    })
                }
            })
        // }
        

    }
    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <Box sx={{ width: '100%' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    sx={styles.tabColor}
                >
                    <Tab sx={{ width: "100%", maxWidth: "172px", fontSize: "14px", fontWeight: "500", lineHeight: "20px", textTransform: "none" }} value="Transaction History" label="Transaction History" />
                    <Tab sx={{ width: "100%", maxWidth: "172px", fontSize: "14px", fontWeight: "500", lineHeight: "20px", textTransform: "none" }} value="Withdraw Request" label="Withdraw Request" />
                </Tabs>
            </Box>

            {value == "Transaction History" && <Box >
                {(!userResponse?.mc) ?
                    <Box>
                        <Box sx={{ width: "80%" }}>
                            <Skeleton animation="wave" sx={{ height: "60px", background: "rgb(0 0 0 / 6%)" }} />
                            <Skeleton animation="wave" sx={{ background: "rgb(0 0 0 / 6%)" }} />
                        </Box>
                        <Box sx={{ width: "80%" }}>
                            <Skeleton animation="wave" sx={{ height: "60px", background: "rgb(0 0 0 / 6%)" }} />
                            <Skeleton animation="wave" sx={{ background: "rgb(0 0 0 / 6%)" }} />
                        </Box>
                        <Box sx={{ width: "80%" }}>
                            <Skeleton animation="wave" sx={{ height: "60px", background: "rgb(0 0 0 / 6%)" }} />
                            <Skeleton animation="wave" sx={{ background: "rgb(0 0 0 / 6%)" }} />
                        </Box>
                    </Box>
                    :
                    <Paper sx={{ width: '100%', overflow: 'hidden', display: "flex", flexDirection: "column", gap: "16px", boxShadow: "none" }}>
                        <Box sx={{display:"flex",flexDirection:{sm:"row",xs:"column"},gap:"24px"}}>
                           <Box sx={{ display: "flex", position: "relative", alignItems: "center",maxWidth: "220px",width:"100%" }}>
                                <Calendar value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" readOnlyInput hideOnRangeSelection placeholder="DD/MM/YY - DD/MM/YY" style={{ border: "1px solid", borderColor: "#CBD5E1", width: "100%", height: "36px", fontSize: "14px", padding: "0px", color: "grey.700", borderRadius: "4px", maxWidth: "220px",fontWeight:"400",boxShadow:"none",outline:"none"}} />
                                <CalendarTodayOutlinedIcon sx={{position:"absolute",right:"10px",pointerEvents: "none",color:"grey",width:"20px",height:"20px"}}/>
                           </Box>
                            <MultiSelect value={selectedTransactionType} onChange={(e)=>setSelectedTransactionType(e.value)} options={transaction_types} optionLabel="name" placeholder="Transaction type" maxSelectedLabels={3} className="w-full md:w-20rem" style={{ border: "1px solid", borderColor: "#CBD5E1", width: "100%", height: "36px", fontSize: "14px", padding: "0px", color: "grey.700", borderRadius: "4px", maxWidth: "190px",fontWeight:"400",boxShadow:"none",outline:"none" }} />
                            <MultiSelect value={selectedWallet} onChange={(e)=>setSelectedWallet(e.value)} options={wallet_types} optionLabel="name" placeholder="Wallet" maxSelectedLabels={3} className="w-full md:w-20rem" style={{ border: "1px solid", borderColor: "#CBD5E1", width: "100%", height: "36px", fontSize: "14px", padding: "0px", color: "grey.700", borderRadius: "4px", maxWidth: "190px",fontWeight:"400",boxShadow:"none" }} />
                        </Box>
                        <TableContainer sx={{ maxHeight: "80vh" }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth ,backgroundColor:"#F8FAFC"}}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredTransactions?.map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    if (column.id === 'amount') {
                                                        return (
                                                            <TableCell
                                                                key={column.id}
                                                                align={column.align}
                                                                style={{
                                                                    color: row.transaction_type === 'c' ? 'green' : 'red',
                                                                }}
                                                            >
                                                                {formatAmount(value, row.transaction_type)}
                                                            </TableCell>
                                                        );
                                                    }
                                                    if (column.id === 'transaction_sub_type') {
                                                        return (
                                                            <TableCell key={column.id} align={column.align}>
                                                                {capitalizeFirstLetter(value)}
                                                            </TableCell>
                                                        );
                                                    }
                                                    if (column.id === 'tag') {
                                                        return (
                                                            <TableCell key={column.id} align={column.align}>
                                                                {wallet(value)}
                                                            </TableCell>
                                                        );
                                                    }
                                                    return (
                                                        <TableCell key={column.id} align={column.align} >
                                                            {column.format && typeof value === 'number'
                                                                ? column.format(value)
                                                                : value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                }
            </Box>}
            {value == "Withdraw Request" && <Box>
            {(!userResponse?.mc) ?
                    <Box>
                        <Box sx={{ width: "80%" }}>
                            <Skeleton animation="wave" sx={{ height: "60px", background: "rgb(0 0 0 / 6%)" }} />
                            <Skeleton animation="wave" sx={{ background: "rgb(0 0 0 / 6%)" }} />
                        </Box>
                        <Box sx={{ width: "80%" }}>
                            <Skeleton animation="wave" sx={{ height: "60px", background: "rgb(0 0 0 / 6%)" }} />
                            <Skeleton animation="wave" sx={{ background: "rgb(0 0 0 / 6%)" }} />
                        </Box>
                        <Box sx={{ width: "80%" }}>
                            <Skeleton animation="wave" sx={{ height: "60px", background: "rgb(0 0 0 / 6%)" }} />
                            <Skeleton animation="wave" sx={{ background: "rgb(0 0 0 / 6%)" }} />
                        </Box>
                    </Box>
                    :
                <Paper sx={{ width: '100%', overflow: 'hidden', display: "flex", flexDirection: "column", gap: "16px", boxShadow: "none" }}>
                        {/* <Box sx={{display:"flex",flexDirection:"row",gap:"24px"}}>
                            <DatePicker name="date" value={filters?.date?filters.date:null} onChange={handleFilterChange} className="pan-datepicker" format="DD/MM/YYYY" sx={{ "&.pan-datepicker .MuiInputBase-root.MuiOutlinedInput-root": { border: "1px solid", borderColor: "grey.400", width: "100%", height: "36px", fontSize: "16px", padding: "14px", color: "grey.700", border: "none", borderRadius: "4px", maxWidth: "190px" } }} />
                            <Dropdown name="transaction_type" value={filters.transaction_type} onChange={handleFilterChange} options={transaction_types} optionLabel="name" placeholder="Transaction type" style={{ border: "1px solid", borderColor: "grey.400", width: "100%", height: "36px", fontSize: "16px", padding: "14px", color: "grey.700", borderRadius: "4px", maxWidth: "190px" }} className="w-full md:w-14rem"/> */}
                            {/* <Select onChange={(e) => setFilter({ transaction_type: e.target.value })} sx={{ border: "1px solid", borderColor: "grey.400", width: "100%", height: "36px", fontSize: "16px", padding: "14px", color: "grey.700", border: "none", borderRadius: "4px", maxWidth: "190px" }}>
                                <MenuItem value={"Transfer"}>Transfer</MenuItem>
                                <MenuItem value={"Purchase"}>Purchase</MenuItem>
                                <MenuItem value={"Deposited"}>Deposited</MenuItem>
                                <MenuItem value={"Withdraw"}>Withdraw</MenuItem>
                            </Select>
                            <Select onChange={(e) => setFilter({ wallet: e.target.value })} sx={{ border: "1px solid", borderColor: "grey.400", width: "100%", height: "36px", fontSize: "16px", padding: "14px", color: "grey.700", borderRadius: "4px", maxWidth: "190px",fontFamily:"poppins" }}>
                                <MenuItem value={"Mayi Pay"}>Mayi Pay</MenuItem>
                                <MenuItem value={"Mayi Earnings"}>Mayi Earnings</MenuItem>
                            </Select> */}
                        {/* </Box> */}
                        <Box sx={{display:"flex",flexDirection:{sm:"row",xs:"column"},gap:"24px"}}>
                           <Box sx={{ display: "flex", position: "relative", alignItems: "center",maxWidth: "220px",width:"100%" }}>
                                <Calendar value={withdrawDates} onChange={(e) => setWithdrawDates(e.value)} selectionMode="range" readOnlyInput hideOnRangeSelection placeholder="DD/MM/YY - DD/MM/YY" style={{ border: "1px solid", borderColor: "#CBD5E1", width: "100%", height: "36px", fontSize: "14px", padding: "0px", color: "grey.700", borderRadius: "4px", maxWidth: "220px",fontWeight:"400",boxShadow:"none",outline:"none"}} />
                                <CalendarTodayOutlinedIcon sx={{position:"absolute",right:"10px",pointerEvents: "none",color:"grey",width:"20px",height:"20px"}}/>
                           </Box>
                            <MultiSelect value={withdrawStatus} onChange={(e)=>setWithdrawStatus(e.value)} options={withdraw_statuses} optionLabel="name" placeholder=" Status" maxSelectedLabels={3} className="w-full md:w-20rem" style={{ border: "1px solid", borderColor: "#CBD5E1", width: "100%", height: "36px", fontSize: "14px", padding: "0px", color: "grey.700", borderRadius: "4px", maxWidth: "190px",fontWeight:"400",boxShadow:"none",outline:"none" }} />
                            {/* <MultiSelect value={selectedWallet} onChange={(e)=>setSelectedWallet(e.value)} options={wallet_types} optionLabel="name" placeholder="Wallet" maxSelectedLabels={3} className="w-full md:w-20rem" style={{ border: "1px solid", borderColor: "#CBD5E1", width: "100%", height: "36px", fontSize: "14px", padding: "0px", color: "grey.700", borderRadius: "4px", maxWidth: "190px",fontWeight:"400",boxShadow:"none" }} /> */}
                        </Box>
                        <TableContainer sx={{ maxHeight: "35vh" }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Request ID</TableCell>
                                        <TableCell>Current</TableCell>
                                        <TableCell>withdrawal Amount</TableCell>
                                        <TableCell>Deductions</TableCell>
                                        <TableCell>Amount</TableCell>
                                        <TableCell>Balance</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredWithdraw.map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                               
                                                     <TableCell>
                                                        {formatDate(row.created_on)}
                                                        </TableCell>   
                                                        <TableCell>
                                                        {row.transactionid}
                                                        </TableCell>
                                                        <TableCell>
                                                        ₹ {row.current_amount}
                                                        </TableCell>
                                                        <TableCell>
                                                        ₹ {row.without_deduction}
                                                        </TableCell>
                                                        <TableCell>
                                                        ₹ {row.deduction_amount}
                                                        </TableCell>
                                                        <TableCell sx={{color:row.type=="Deb"?"red":"green"}}>
                                                        ₹ {row.amount}
                                                        </TableCell>
                                                        <TableCell>
                                                        ₹ {row.balance}
                                                        </TableCell>
                                                        <TableCell>
                                                        {row.type=="Deb"?"Debited":"Credited"}
                                                        </TableCell>
                                                        <TableCell sx={{textTransform:"capitalize" ,}}>
                                                            <Typography sx={{backgroundColor:row.status=="requested"?"#F59E0B":row.status=="completed"?"#22C55E":row.status=="inprogress"?"#0EA5E9":"red",width:"max-content",padding:"0px 10px",borderRadius:"15px",color:"white"}}>
                                                            {row.status}
                                                            </Typography>
                                                        
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.status=="requested"&&

                                                            
                                                            <Box sx={{display:"flex",gap:"15px"}} >
                                                                <Button onClick={()=>{dispatch(updateOpenWithdrawRequest(true));dispatch(updateWithdrawAmount(row.without_deduction));dispatch(updateWithdrawAccount(row.bank_account_id));dispatch(updateWithdrawAmountChange(true));dispatch(updateWithdrawId(row.id));dispatch(updateWithdraw(true));dispatch(updateCurrentAmount(row.current_amount))}} sx={{}} variant="contained">Edit</Button>
                                                                <Button  disabled={isLoading} sx={{color:"#EF4444s",color:"#EF4444",borderColor:"#EF4444","&:hover":{borderColor:"#EF4444",color:"#EF4444",}}} variant="outlined" onClick={()=>{dispatch(updateWithdrawId(row.id));statusUpdate(row.id,"cancelled");}}>{isLoading? <CircularProgress color="inherit" size={30}/> : "Cancel"}</Button>
                                                            </Box>}
                                                        </TableCell>
                                                                                                                 
                                            
                                                  
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>}
               
            </Box>}
        </Box>
    )
}